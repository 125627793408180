import styled, { ThemeProvider } from "styled-components";
import { useState } from "react";
import Navbar from "./components/Navbar";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import ProfileSection from "./components/ProfileSection";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Footer from "./components/Footer";
import Experience from "./components/Experience";
import Education from "./components/Education";
import ProjectDetails from "./components/ProjectDetails";
import Contact from "./components/Contact";
import { darkTheme, lightTheme } from "./themes/themeModes.js";
import ReactStarfield from "react-starfield";

const Body = styled.div`
  background-color: ${({ theme }) => theme.bg};
  width: 100%;
  overflow-x: hidden;
`;

const Wrapper = styled.div`
  background: linear-gradient(
      38.73deg,
      rgba(204, 0, 187, 0.15) 0%,
      rgba(201, 32, 184, 0) 50%
    ),
    linear-gradient(
      141.27deg,
      rgba(0, 70, 209, 0) 50%,
      rgba(0, 70, 209, 0.15) 100%
    );
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 98%, 0 100%);
`;
function App() {
  // const systemPreference = window.matchMedia(
  //   "(prefers-color-scheme: dark)"
  // ).matches;
  const [isDarkMode, setIsDarkMode] = useState(
    !localStorage.getItem("theme")
      ? true
      : JSON.parse(localStorage.getItem("theme"))
  );
  const theme = isDarkMode === true ? darkTheme : lightTheme;
  const toggleTheme = () => {
    setIsDarkMode((prevMode) => {
      localStorage.setItem("theme", !prevMode);
      return !prevMode;
    });
  };
  const [openModal, setOpenModal] = useState({ state: false, project: null });
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Navbar isDarkMode={isDarkMode} toggleTheme={toggleTheme} />
        <Body>
        <ReactStarfield/>
          <ProfileSection />
          <Wrapper>
            <Skills />
            <Experience />
          </Wrapper>
          <Projects setOpenModal={setOpenModal} />
          <Wrapper>
            <Education />
            <Contact isDarkMode={isDarkMode} />
          </Wrapper>
          <Footer />
          {openModal.state && (
            <ProjectDetails openModal={openModal} setOpenModal={setOpenModal} />
          )}
        </Body>
      </Router>
    </ThemeProvider>
  );
}

export default App;
